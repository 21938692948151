<!--分时原材料运输统计-->
<template>
    <div class="materialTransporStatistics table-bg">
        <reportTable
            code="PurchaseTransportDay"
            :query="query"
            date-label="进场日期"
            @generateComplete="generateComplete"
            :percentage="percentage"
            :table-title="tableTitle"
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">出场日期：</label>
                    <el-date-picker
                        v-model="tareTime"
                        type="daterange"
                        placeholder="请选择"
                        value-format="yyyy-MM-dd"
                        @change="changeTareTime"
                        clearable
                    >
                    </el-date-picker>
                </div>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_id"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">运输单位：</label>
                    <el-input placeholder="请输入" v-model="query.transport_name"></el-input>
                </div>
                <div class="query-item">
                    <label class="name">原材料分类：</label>
                    <el-select
                        v-model="query.type_dic_code"
                        placeholder="请选择"
                        clearable
                        @change="getMaterialChild"
                    >
                        <el-option
                            v-for="item in materialGroupList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料子类：</label>
                    <el-select
                        v-model="query.child_type_code"
                        placeholder="请选择"
                        clearable
                        @change="getMaterialSpec"
                    >
                        <el-option
                            v-for="item in materialChildList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料规格：</label>
                    <el-select
                        v-model="query.spec_code"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in materialSpecList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
            </template>
            <template #customTable>
                <div class="table-head">
                    <table class="t-table" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="width: 1.2rem" rowspan="2">
                                    序号
                                </th>
                                <th style="width: 1.5rem" rowspan="2">
                                    项目
                                </th>
                                <th style="width: 1.8rem" rowspan="2">
                                    运输单位
                                </th>
                                <th style="width: 1.8rem" rowspan="2">
                                    货源地
                                </th>
                                <th style="width: 1.8rem" rowspan="2">
                                    进场时间
                                </th>
                                <template v-if="titleStations.length>0">
                                    <th :colspan="titleStations.length">
                                        运输单价（元）
                                    </th>
                                    <th style="width: 1.2rem" rowspan="2">
                                        合计
                                    </th>
                                    <th :colspan="titleStations.length">
                                        过磅净重（吨）
                                    </th>
                                    <th :colspan="titleStations.length">
                                        原发数量（吨）
                                    </th>
                                    <th colspan="2">
                                        合计
                                    </th>
                                    <th :colspan="titleStations.length">
                                        运输金额（元）
                                    </th>
                                    <th style="width: 1.2rem" rowspan="2">
                                        合计
                                    </th>
                                    <th :colspan="titleStations.length">
                                        车次
                                    </th>
                                    <th style="width: 1.2rem" rowspan="2">
                                        合计
                                    </th>
                                </template>
                            </tr>
                            <tr v-if="titleStations.length>0">
                                <th v-for="(item,index) in titleStations" :key="'cola'+index" style="width: 1.2rem">
                                    {{ item.station_name }}
                                </th>
                                <th v-for="(item,index) in titleStations" :key="'colb'+index" style="width: 1.2rem">
                                    {{ item.station_name }}
                                </th>
                                <th v-for="(item,index) in titleStations" :key="'colc'+index" style="width: 1.2rem">
                                    {{ item.station_name }}
                                </th>
                                <th style="width: 1.2rem">
                                    过磅净重（吨）
                                </th>
                                <th style="width: 1.2rem">
                                    出厂重量（吨）
                                </th>
                                <th v-for="(item,index) in titleStations" :key="'cold'+index" style="width: 1.2rem">
                                    {{ item.station_name }}
                                </th>
                                <th v-for="(item,index) in titleStations" :key="'cole'+index" style="width: 1.2rem">
                                    {{ item.station_name }}
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table-body">
                    <table
                        v-if="tableList.length>0"
                        class="t-table"
                        cellpadding="0"
                        cellspacing="0"
                    >
                        <tbody>
                            <template v-for="(item1,index1) in tableList">
                                <tr :key="index1">
                                    <td
                                        style="width: 1.2rem"
                                        v-if="mergeCells['index'+index1]!=0"
                                        :rowspan="mergeCells['index'+index1]"
                                    >
                                        {{ item1.index }}
                                    </td>
                                    <td
                                        style="width: 1.5rem"
                                        v-if="mergeCells['material_name'+index1]!=0"
                                        :rowspan="mergeCells['material_name'+index1]"
                                    >
                                        {{ item1.material_name }}
                                    </td>
                                    <td
                                        style="width: 1.8rem"
                                        v-if="mergeCells['transport_name'+index1]!=0"
                                        :rowspan="mergeCells['material_name'+index1]"
                                    >
                                        {{ item1.transport_name }}
                                    </td>
                                    <td
                                        style="width: 1.8rem"
                                        v-if="mergeCells['source_name'+index1]!=0"
                                        :rowspan="mergeCells['source_name'+index1]"
                                    >
                                        {{ item1.source_name }}
                                    </td>
                                    <td style="width: 1.8rem">
                                        {{ item1.min_gross_time }}
                                    </td>
                                    <td v-for="(item2,index2) in titleStations" style="width: 1.2rem" :key="index1+'a'+index2">
                                        {{ item2.adjusted_transport_price }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item1.adjusted_transport_price }}
                                    </td>
                                    <td v-for="(item2,index2) in titleStations" style="width: 1.2rem" :key="index1+'b'+index2">
                                        {{ item2.checked_net_weight }}
                                    </td>
                                    <td v-for="(item2,index2) in titleStations" style="width: 1.2rem" :key="index1+'c'+index2">
                                        {{ item2.delivery_weight }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item1.checked_net_weight }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item1.delivery_weight }}
                                    </td>
                                    <td v-for="(item2,index2) in titleStations" style="width: 1.2rem" :key="index1+'d'+index2">
                                        {{ item2.adjusted_transport_money }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item1.adjusted_transport_money }}
                                    </td>
                                    <td v-for="(item2,index2) in titleStations" style="width: 1.2rem" :key="index1+'e'+index2">
                                        {{ item2.carriage_count }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item1.carriage_count }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div v-else class="el-table__empty-text"></div>
                </div>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import qs from 'qs';
import utils from '@/utils/util';
export default {
    components: { reportTable },
    data() {
        return {
            loading: false,
            query: {
                startTareTime: '',
                endTareTime: '',
                station_id: '',
                transport_name: '',
                type_dic_code: '',
                child_type_code: '',
                spec_code: '',
            },
            titleStations: [],
            tareTime: [],
            stationList: [],
            materialGroupList: [],
            materialChildList: [],
            materialSpecList: [],

            tableTitle: '分时原材料运输统计',
            totalData: {},
            tableList: [],
            // 时间分批大小 ：天
            limit: 10,
            // 进度条
            percentage: 100,
            // 合并单元格配置
            mergeCells: {},
            // 查询分组
            queryArr: null,
        };
    },
    created() {
        this.getStationList();
        this.getMaterialGroup();
    },
    methods: {
        changeTareTime(val) {
            if (val) {
                this.query.startTareTime = val[0];
                this.query.endTareTime = val[1];
            } else {
                this.query.startTareTime = '';
                this.query.endTareTime = '';
            }
        },
        getMonthDays(dateStr) {
            const d = new Date(dateStr);
            return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
        },
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 原材料分类
        async getMaterialGroup() {
            this.materialGroupList = await this.$axios.get('/interfaceApi/procurement/material/group/type/get');
        },
        // 原材料子类
        async getMaterialChild() {
            this.query.child_type_code = '';
            this.query.spec_code = '';
            this.materialChildList = [];
            this.materialSpecList = [];
            const url = `/interfaceApi/procurement/material/group/type/child/get/parent_code?parent_code=${this.query.type_dic_code}`;
            const data = await this.$axios.get(url);
            if (data.length > 0) {
                this.materialChildList = data;
            }
        },
        // 原材料规格
        async getMaterialSpec() {
            this.query.spec_code = '';
            this.materialSpecList = [];
            const url = `/interfaceApi/procurement/material/group/type/spec/get/parent_code?parent_code=${this.query.child_type_code}`;
            const data = await this.$axios.get(url);
            if (data.length > 0) {
                this.materialSpecList = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.loading = true;
                this.tableList = [];
                this.mergeCells = {};
                this.percentage = 0;
                const startTimes = new Date(params.startTime).getTime();
                const endTimes = new Date(params.endTime).getTime();
                const splitTimes = this.limit * 24 * 60 * 60 * 1000;
                const arr = [];
                for (let times = startTimes; times <= endTimes; times += splitTimes) {
                    const start = utils.timeFormat(new Date(times), 'yyyy-MM-dd');
                    let endms = times + splitTimes;
                    if (endms >= endTimes) {
                        endms = endTimes;
                    }
                    const end = utils.timeFormat(new Date(endms), 'yyyy-MM-dd');
                    if (arr.length > 0 && start === end) {
                        break;
                    }
                    arr.push([start, end]);
                }
                this.queryArr = arr;
                let index = 0;
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const pam = Object.assign( {}, params);
                    pam.startTime = arr[i][0];
                    pam.endTime = arr[i][1];
                    const listUrl = `/interfaceApi/report/material_transpor_statistics/data_list?${qs.stringify(pam)}`;
                    const list = await this.$axios.get(listUrl);
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    const pushList = [];
                    // eslint-disable-next-line no-loop-func
                    list.forEach(rows => {
                        index++;
                        rows.forEach(item => {
                            item.index = index;
                            pushList.push(item);
                        });
                    });
                    this.tableList.push(...pushList);
                    if ( Number(i) === 0) {
                        this.loading = false;
                    }
                    if (this.titleStations.length === 0 && this.tableList.length > 0) {
                        this.titleStations = this.tableList[0].list;
                    }
                }
                this.mergeCellsFn(this.tableList);
                if (this.tableList.length > 0) {
                    // 获取合计数据
                    const url = `/interfaceApi/report/material_transpor_statistics/data_total?${qs.stringify(params)}`;
                    const totalData = await this.$axios.get(url);
                    totalData.colspan = 2;
                    this.totalData = totalData;
                }

                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        // 合并单元格方法
        mergeCellsFn(list = []) {
            const mergeProps = ['index', 'material_name', 'transport_name', 'source_name' ];
            list.forEach((item, index) => {
                for (const i in mergeProps) {
                    const prop = mergeProps[i];
                    const prevProp = mergeProps[i - 1];
                    const mergeKey = prop + '' + index;
                    if (this.mergeCells[mergeKey] || !item[prop]) {
                        continue;
                    }
                    let rowspan = 1;
                    let nextIndex = index + 1;
                    // eslint-disable-next-line max-len
                    while (list[nextIndex] && item[prop] === list[nextIndex][prop] && item[prevProp] === list[nextIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[nextIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        nextIndex++;
                    }
                    this.mergeCells[mergeKey] = rowspan;
                    let prevIndex = index - 1;
                    // eslint-disable-next-line max-len
                    while (list[prevIndex] && item[prop] === list[prevIndex][prop] && item[prevProp] === list[prevIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[prevIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        prevIndex--;
                        this.mergeCells[mergeKey] = 0;
                        break;
                    }

                }
            });
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>
</style>


